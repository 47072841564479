import React, { Suspense, useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import SideNav from "Components/SideNav";
import Navbar from "Components/Navbar";
import LoginPage from "Pages/LoginPage";
import LoginEmaiVerify from "Pages/LoginEmaiVerify";
import ResetPassLogin from "Pages/ResetPassLogin";
import SuspanceLoading from "Components/SuspanceLoading";
import MobileNavBar from "Components/MobileNavBar";
import PrunePartnerProgram from "Pages/PrunePartnerProgram";

const Layout = ({ children }) => {
  const xs = useMediaQuery("(max-Width: 600px)");
  const sm = useMediaQuery("(min-width: 601px) and (max-width: 900px)");
  // const md = useMediaQuery("(min-width: 901px) and (max-width: 1200px)");
  // const lg = useMediaQuery("(min-width: 1201px) and (max-width: 1536px)");
  // const xl = useMediaQuery("(min-width: 1537px)");

  const urlToCompare = window.location.pathname;
  const [renderLogin, setRenderLogin] = useState(null);

  useEffect(() => {
    urlToCompare?.includes("/login")
      ? setRenderLogin(<LoginPage />)
      : urlToCompare === "/"
      ? setRenderLogin(<PrunePartnerProgram />)
      : urlToCompare?.includes("/email_verification")
      ? setRenderLogin(<LoginEmaiVerify />)
      : urlToCompare?.includes("/reset_password")
      ? setRenderLogin(<ResetPassLogin />)
      : setRenderLogin(
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            {/* SideNavbar */}
            <Box
              sx={{
                position: "relative",
                minWidth: "15.625rem",
                maxWidth: "15.625rem",
                display: { xs: "none", sm: "block", md: "block" },
              }}
            >
              <Box
                sx={{
                  position: "fixed",
                  top: "0px",
                  left: "0px",
                  minWidth: "15.625rem",
                  maxWidth: "15.625rem",
                }}
              >
                <SideNav />
              </Box>
            </Box>

            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "block",
                    md: "block",
                  },
                  position: "sticky",
                  top: 0,
                  zIndex: 10,
                  height: "8vh",
                }}
              >
                <Navbar />
              </Box>

              <Box
                sx={{
                  display: {
                    xs: "block",
                    sm: "none",
                    md: "none",
                  },
                  position: "sticky",
                  top: 0,
                  zIndex: 10,
                }}
              >
                <MobileNavBar />
              </Box>

              <main
                style={{
                  minHeight: "92vh",
                  backgroundColor: "#fafaff",
                  padding: xs ? ".5rem" : sm ? "1.44rem" : "1.44rem",
                }}
              >
                {children}
              </main>
            </Box>
          </Box>
        );
  }, [urlToCompare]);

  return (
    <>
      <Suspense fallback={<SuspanceLoading />}>{renderLogin}</Suspense>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
