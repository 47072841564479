import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import {
  Box,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Typography,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import MrButton from "Components/MrButton";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import LoginPageHeding from "Components/LoginHeading/index";
import LoginPageImage from "Components/LoginPageImage/index";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { userLogin } from "../../Redux/Features/UserAuthSlice";
import toast from "react-hot-toast";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    userId: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for toggle show password.

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    dispatch(
      userLogin({
        url: `${process.env.REACT_APP_URI}reseller/login/`,
        data: {
          step: "login",
          username: formData.userId,
          password: formData.password,
        },
      })
    ).then((res) => {
      setLoading(false);
      if (res?.payload?.message === "OTP sent to your email.") {
        toast.success("OTP has been sent to your email id");
        localStorage.setItem("token", res?.payload?.token);
        localStorage.setItem("email", res?.payload?.emailid);
        localStorage.setItem("userName", res?.payload?.reseller_username);
        navigate("/email_verification");
      } else if (res?.payload?.message === "Reseller logged in successfully") {
        toast.success(
          res?.payload?.message || "Reseller logged in successfully"
        );
        localStorage.setItem("token", res?.payload?.token);
        localStorage.setItem("email", res?.payload?.emailid);
        localStorage.setItem("userName", res?.payload?.reseller_username);
        localStorage.setItem("current_country", res?.payload?.current_country);
        localStorage.setItem(
          "reseller_username",
          res?.payload?.reseller_username_re
        );
        navigate("/dashboard");
      } else {
        toast.error(res?.payload?.message || "Something Went Wrong");
      }
    });
  };

  return (
    <>
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            display: { xs: "none", sm: "block" },
            width: "50%",
          }}
        >
          <LoginPageImage flexDirection={"column"} />
        </Box>

        <Box
          sx={{
            height: "100%",
            width: {
              xs: "100%",
              sm: "50%",
              md: "50%",
            },
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              px: { xs: "1rem", sm: "8.8rem", md: "8.8rem" },
            }}
          >
            <LoginPageHeding />
            <form
              onSubmit={handleSubmit}
              style={{
                width: "100%",
              }}
            >
              <Box
                sx={{
                  mt: "2rem",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <FormControl sx={{ width: "100%" }}>
                  <Typography
                    variant="body1"
                    fontWeight={600}
                    sx={{ fontSize: "1rem", mb: ".5rem" }}
                  >
                    User ID
                  </Typography>
                  <TextField
                    required
                    type="text"
                    size="small"
                    value={formData?.userId}
                    name="userId"
                    onChange={handleChange}
                    sx={{
                      "& .MuiInputBase-root": {
                        width: "100%",
                        height: "3rem",
                        borderRadius: "0.625rem",
                      },
                    }}
                  />
                </FormControl>
                <FormControl sx={{ mt: 2 }}>
                  <Typography
                    variant="body1"
                    fontWeight={600}
                    sx={{ fontSize: "1rem", mb: ".5rem" }}
                  >
                    Password
                  </Typography>
                  <OutlinedInput
                    required
                    sx={{
                      height: "3rem",
                      width: "100%",
                      borderRadius: "0.625rem",
                    }}
                    value={formData?.password}
                    name="password"
                    onChange={handleChange}
                    id="outlined-adornment-password"
                    size="small"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                {loading ? (
                  <LoadingButton
                    sx={{
                      mt: 3,
                      height: "3rem",
                      borderRadius: "0.625rem",
                      fontSize: "3rem",
                      "&.MuiLoadingButton-icon": {
                        fontSize: "3rem",
                      },
                    }}
                    color="secondary"
                    loading={loading}
                    variant="contained"
                  ></LoadingButton>
                ) : (
                  <Box mt={3}>
                    <MrButton
                      type={"submit"}
                      fontSize={"1rem"}
                      width={"100%"}
                      textTransform={"none"}
                      height={"3rem"}
                      borderRadius={"0.625rem"}
                      fontWeight={"300"}
                      variant={"contained"}
                      endIcon={<ArrowForwardIcon />}
                    >
                      Login
                    </MrButton>
                  </Box>
                )}
              </Box>
            </form>

            {/* Divider Data */}
            {/* <Box
              sx={{
                mt: "1rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                // border: "1px solid blue",
                width: "100%",
                gap: ".5rem",
              }}
            >
              <Box sx={{ borderBottom: "1px solid #ccc", width: "50%" }} />
              <Typography
                variant="body2"
                fontWeight={500}
                sx={{ fontSize: ".8rem" }}
              >
                or
              </Typography>
              <Box sx={{ borderBottom: "1px solid #ccc", width: "50%" }} />
            </Box> */}

            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: "1rem",
                // border: "1px solid red",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "1rem", fontWeight: "600" }}
              >
                Want to become a Partner? &nbsp;
                <Typography
                  onClick={() => navigate("/registration")}
                  variant="caption"
                  sx={{
                    fontWeight: "600",
                    fontSize: "1rem",
                    color: "primary.main",
                    cursor: "pointer",
                    textDecoration: "underline",
                    "&:hover": { textDecoration: "none" },
                  }}
                >
                  Sign up here
                </Typography>
              </Typography>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LoginPage;
