import DucumentUpload from "Pages/DucumentUpload";
import PrunePartnerProgram from "Pages/PrunePartnerProgram";
import { lazy } from "react";
const HomePage = lazy(() => import("Pages/HomePage"));
const StosksPage = lazy(() => import("Pages/StosksPage"));
const OrderPage = lazy(() => import("Pages/OrderPage"));
const LoginPage = lazy(() => import("Pages/LoginPage"));
const CheckoutPage = lazy(() => import("Pages/CheckoutPage"));
const AnalyticsPage = lazy(() => import("Pages/AnalyticsPage"));
const ResetPassLogin = lazy(() => import("Pages/ResetPassLogin"));
const EsimDetailPage = lazy(() => import("Pages/EsimDetailPage"));
const LoginEmaiVerify = lazy(() => import("Pages/LoginEmaiVerify"));
const DkycPendingPage = lazy(() => import("Pages/DkycPendingPage"));
const CountryPlansPage = lazy(() => import("Pages/CountryPlansPage"));
const TransactionsPage = lazy(() => import("Pages/TransactionsPage"));
const OrderDetailsPage = lazy(() => import("Pages/OrderDetailsPage"));
const RegionalPlansPage = lazy(() => import("Pages/RegionalPlansPage"));
const CustomerDetailsPage = lazy(() => import("Pages/CustomerDetailsPage"));
const InternationalSimPage = lazy(() => import("Pages/InternationalSimPage"));

export const routes = [
  {
    path: "/",
    component: <PrunePartnerProgram />,
    protected: true,
  },
  {
    path: "/dashboard",
    component: <HomePage />,
    protected: true,
  },
  {
    path: "/analytics",
    component: <AnalyticsPage />,
    protected: true,
  },
  {
    path: "/international-sim",
    component: <InternationalSimPage />,
    protected: true,
  },
  {
    path: "/d-kyc",
    component: <DkycPendingPage />,
    protected: true,
  },
  {
    path: "/orders",
    component: <OrderPage />,
    protected: true,
  },
  {
    path: "/transaction",
    component: <TransactionsPage />,
    protected: true,
  },
  {
    path: "/order-details/:orderId",
    component: <OrderDetailsPage />,
    protected: true,
  },
  {
    path: "/login",
    component: <LoginPage />,
    protected: true,
  },
  {
    path: "/email_verification",
    component: <LoginEmaiVerify />,
    protected: true,
  },
  {
    path: "/reset_password",
    component: <ResetPassLogin />,
    protected: true,
  },
  {
    path: "/country-plans/:id",
    component: <CountryPlansPage />,
    protected: true,
  },
  {
    path: "/regional-plans/:regionalName",
    component: <RegionalPlansPage />,
    protected: true,
  },
  {
    path: "/customerDetails/:planId/:countryCode",
    component: <CustomerDetailsPage />,
    protected: true,
  },
  {
    path: "/checkout",
    component: <CheckoutPage />,
    protected: true,
  },
  {
    path: "/international-sim/details/:emailId",
    component: <EsimDetailPage />,
    protected: true,
  },
  {
    path: "mystock",
    component: <StosksPage />,
    protected: true,
  },
  {
    path: "/document-upload",
    component: <DucumentUpload />,
    protected: true,
  },
];
